.App {
  position: relative;
  max-width: 940px;
  width: 100%;
  background-color: white;
  border-radius: 6px;
  margin: 40px auto;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  overflow: hidden;
  z-index: 1;
}

.background-stripe {
  position: absolute;
  background-color: #b03b3c;
  top: 448px;
  left: 0;
  right: 0;
  height: 60px;
  z-index: 0;
}

.main {
  padding: 70px 40px 0 40px;
}

.main p {
  line-height: 1.8;
}

.bottom {
  display: flex;
  justify-content: space-between;
  line-height: 1.8;
}

.bottom > div {
  margin-bottom: 20px;
}

.hor-stack {
  display: flex;
  justify-content: center;
}

.hor-stack > div {
  width: 150px;
}

@media screen and (max-width: 960px) {
  .App {
      margin: 0 auto;
      border-radius: 0;
  }
}

@media screen and (max-width: 760px) {
  .bottom {
      display: block;
  }
  .hor-stack {
      justify-content: flex-start;
  }
}

h2 {
  color: #c22b2c;
  margin: 0;
}

a {
  color: #c22b2c;
  text-decoration: none;
}

.Menu {
  text-align: center;
  margin: 100px 0;
}

.menu-overlay-close-btn {
    border: none;
    background: rgba(255, 255, 255, 0.9);
    padding: 6px 20px;
    border-radius: 16px;
    margin: 0 0 10px 0;
    outline: none;
    font-size: 18px;
}

.menu-btn {
  border: 1px solid #c22b2c;
  font-size: 22px;
  color: #c22b2c;
  background: transparent;
  outline: none;
  border-radius: 35px;
  padding: 10px 40px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: ease 0.4s;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.menu-btn:hover {
  background: #c22b2c;
  color: white;
  box-shadow: 2px 2px 8px 0 #a6a6a6;
}

.menu-overlay {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.72);
  text-align: center;
  overflow-y: auto;
  padding: 30px 0px;
}
.menu-overlay.visible {
    display: block;
}
.menu-overlay .react-pdf__Page__canvas{
    margin: 10px auto;
}

.social {
  display: flex;
  justify-content: flex-start;
  padding: 0;
  margin: 20px 5px;
}

.social li {
  list-style: none;
  margin: 0 15px 0 0;
}

.social li a {
  font-size: 22px;
}
