.menu-overlay-close-btn {
    border: none;
    background: hsla(0,0%,100%,.9);
    padding: 6px 20px;
    border-radius: 16px;
    margin: 0 0 10px;
    outline: none;
    font-size: 18px
}

.menu-btn {
    border: 1px solid #c22b2c;
    font-size: 22px;
    color: #c22b2c;
    background: transparent;
    border-radius: 35px;
    padding: 10px 40px;
    margin-bottom: 15px;
    cursor: pointer;
    -webkit-transition: .4s ease;
    transition: .4s ease;
    outline: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent
}

.menu-btn:hover {
    background: #c22b2c;
    color: #fff;
    box-shadow: 2px 2px 8px 0 #a6a6a6
}

.menu-overlay {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.72);
    text-align: center;
    overflow-y: auto;
    padding: 30px 0
}

.menu-overlay.visible {
    display: block
}

.menu-overlay .react-pdf__Page__canvas {
    margin: 10px auto
}